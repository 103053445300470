tenderView.controller('tenderViewDocumentsUploadModalCtrl', function(
    $scope,
    $rootScope,
    $uibModalInstance,
    data,
    ntFlash,
    documentsService,
    tenderService,
    agreementService,
    $state
) {
    $scope.tenderId = data.tenderId;
    $scope.hasPreQualification  = (data.hasPreQualification) ?
        data.hasPreQualification : ['aboveThresholdEU', 'esco', 'closeFrameworkAgreementUA'].includes($scope.procurementMethodType)
                                 || $scope.isCompetitiveDialogue;;
    $scope.bidId = data.bidId;
    $scope.awardId = data.awardId;
    $scope.contractId = data.contractId;
    $scope.procurementMethodType = data.procurementMethodType;
    $scope.isEsco = data.isEsco;
    $scope.agreementId = data.agreementId;
    $scope.tenderStatus = data.tenderStatus;
    $scope.isMilestones24h = data.isMilestones24h;
    $scope.isContractTempalte = data.procurementMethodType === 'contractTemplate';
    $scope.isMilestonesAlp = data.isMilestonesAlp;

    $scope.isOwner = data.actions && (data.actions.tender_owner || data.actions.budget_owner || data.actions.contract_owner);

    $scope.files = [];
    $scope.lots = data.lots;
    $scope.lotValues = data.lotValues || [];
    if ($scope.lots) {
        $scope.lots.forEach(elem => {
            elem.disabled = true;
            if($state.current.name === 'tenderView.documents'){
                elem.disabled = false;
            } else {
                for (const item of $scope.lotValues) {
                    if (elem.id === item.relatedLot) elem.disabled = false;
                }
            }
        });
    }

    $scope.items = data.items;


    $scope.isMultilotTender = $scope.lots && $scope.lots.length ? true : false;
    $scope.procurementMethodType = data.procurementMethodType;
    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue(data);
    $scope.isCompetitiveDialogue2Stage = tenderService.isCompetitiveDialogue2Stage(data);
    $scope.loadInProgress = false;
    $scope.notLoadedFiles = [];

    // $scope.hasPreQualification = ['aboveThresholdEU', 'esco', 'closeFrameworkAgreementUA'].includes($scope.procurementMethodType)
    //                              || $scope.isCompetitiveDialogue;

    $scope.documentTypeAndConfidentialityScheme = tenderService.setDocumentTypeAndConfidentialityScheme(
                                                        $scope.tenderStatus,
                                                        $scope.procurementMethodType,
                                                        $scope.isMilestones24h,
                                                        $scope.isMilestonesAlp,
                                                        $scope.hasPreQualification
        );


    if($scope.documentTypeAndConfidentialityScheme.buyerOnly){
        $scope.document = {
            documentOf: "tender",
            relatedItem: "",
            documentType: "",
            language: "",
        };
    } else {
        $scope.document = {
            documentOf: "tender",
            relatedItem: "",
            documentType: "",
            confidentiality: "",
            confidentialityRationale: "",
            language: "",
        };
    }

    $scope.upload = function() {

        // console.log($scope.tenderId)
        // console.log($scope.bidId)
        // console.log($scope.files)
        // console.log($scope.awardId)
        // console.log($scope.contractId)
        // console.log($scope.procurementMethodType)
        // console.log($scope.isEsco)
        // console.log($scope.agreementId)
        // console.log($scope.tenderStatus)
        // console.log($scope.isMilestones24h)
        // console.log($scope.isMilestonesAlp)


        if ($scope.files && $scope.files.length) {
            $scope.loadInProgress = true;
            var request;
            let file = $scope.files.pop();
            if ( $scope.document.documentOf == "tender" || !$scope.isOwner ) {
                console.log('$scope.document.documentOf == "tender" || !$scope.isOwner')
                if ($scope.procurementMethodType === 'closeFrameworkAgreementUA' && $scope.isOwner && $scope.agreementId) {
                    console.log('$scope.procurementMethodType === \'closeFrameworkAgreementUA\' && $scope.isOwner && $scope.agreementId')
                    $scope.files.push(file);
                    documentsService.uploadToDocumentService($scope.files).then((documents) => {
                        Promise.all(documents.map((document) => {
                            if ($scope.document.language) {
                                document.language = $scope.document.language;
                                document.documentType = $scope.document.documentType;
                            }
                            return agreementService.uploadDocument({
                                tenderId: $scope.tenderId,
                                agreementId: $scope.agreementId,
                            }, document)
                        })).then(()=>{
                            ntFlash.success(gettext('Документы загружены'));
                            $uibModalInstance.close();
                        }).catch((e) => {
                            console.error(e);
                            ntFlash.error(gettext('Ошибка при загрузке документов'));
                            $uibModalInstance.close();
                        })
                    })

                } else if ($scope.document.documentType !== "" && !$scope.isOwner) {

                    // console.log('$scope.document.documentType !== "" && !$scope.isOwner')
                    // console.log($scope.document.documentType)

                    var type = "";
                    if (['aboveThresholdEU',
                         // 'closeFrameworkAgreementUA',
                         'esco',
                         'competitiveDialogueEU',
                         'competitiveDialogueEU.stage2'
                        ].includes($scope.procurementMethodType)
                    ) {
                        switch ( $scope.document.documentType ) {
                            case 'technicalSpecifications':
                                type = 'documents';
                                break;
                            case 'qualificationDocuments':
                                type = 'documents';
                                break;
                            case 'commercialProposal':
                                type = 'financialDocuments';
                                break;
                            case 'billOfQuantity':
                                type = 'financialDocuments';
                                break;
                            case 'eligibilityDocuments':
                                type = 'eligibilityDocuments';
                                break;
                            case 'evidence':
                                type = 'documents';
                                break;
                            case 'winningBid':
                                type = 'financialDocuments';
                                break;
                            default: type = 'documents';
                        }
                    } else {
                        type = 'documents';
                    }
                    const fileData = {
                        documentOf: "tender",
                        type: type,
                        documentType: $scope.document.documentType,
                        confidentiality: $scope.document.confidentiality,
                        confidentialityRationale: $scope.document.confidentialityRationale
                    }
                    if($scope.document.language != ''){
                        fileData.language = $scope.document.language
                    }

                    // console.log($scope.document)

                    documentsService.saveBid( file, $scope.tenderId, {
                            bidId: $scope.bidId,
                            docData: fileData

                        } )
                        .then( function( response ) {
                            if ( response ) {
                                var bidData = {
                                    documentType: $scope.document.documentType,
                                    documentOf: $scope.document.documentOf
                                };
                                if ( $scope.document.documentOf !== 'tender' ) {
                                    bidData.relatedItem = $scope.document.relatedItem;
                                }
                                if ( $scope.document.confidentiality === "buyerOnly" ) {
                                    console.log($scope.document.confidentialityRationale);
                                    bidData.confidentialityRationale = $scope.document.confidentialityRationale;
                                    bidData.confidentiality = $scope.document.confidentiality;
                                }
                                documentsService.edit( $scope.tenderId, response.data.id, {
                                        bidId: $scope.bidId,
                                        documentsType: type
                                    }, bidData )
                                    .then( function() {
                                        if ($scope.files && $scope.files.length) {
                                            $scope.upload();
                                        } else {
                                            $scope.loadInProgress = false;
                                            if (!$scope.notLoadedFiles.length) {
                                                ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                            } else {
                                               ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                            }
                                            $uibModalInstance.close();
                                        }
                                    });
                            }
                        } ).catch( function( response ) {
                            //console.log('1 File upload error:', file.name);
                            $scope.notLoadedFiles.push(file.name);
                            if ($scope.files && $scope.files.length) {
                                $scope.upload();
                            } else {
                                //console.log('Больше нет файлов');
                                ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                $uibModalInstance.close();
                            }
                        });
                } else if (!$scope.isOwner) {
                    console.log('!$scope.isOwner')
                    request = documentsService.save($scope.file, $scope.tenderId, {bidId: $scope.bidId})
                    .catch( function( response ) {
                        //console.log('3 File upload error:', file.name);
                        $scope.notLoadedFiles.push(file.name);
                        if ($scope.files && $scope.files.length) {
                            $scope.upload();
                        } else {
                            //console.log('Больше нет файлов');
                            ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                            $uibModalInstance.close();
                        }
                    });
                }
                else if (!$scope.contractId) {

                    if($scope.isContractTempalte){
                        $scope.document.documentType = 'contractProforma'
                        $scope.document.documentOf = 'tender'
                    }

                    console.log('!$scope.contractId')
                    documentsService.save(file, $scope.tenderId)
                        .then(function(response) {
                            if (response) {
                                if ($scope.document.documentType !== 'other') {
                                documentsService.edit($scope.tenderId, response.data.data.id, undefined, {documentType: $scope.document.documentType })
                                    .then(function() {
                                        if ($scope.files && $scope.files.length) {
                                            $scope.upload();
                                        } else {
                                            $scope.loadInProgress = false;
                                            if (!$scope.notLoadedFiles.length) {
                                                ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                            } else {
                                               ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                            }
                                            $uibModalInstance.close();
                                        }
                                    });
                                } else {
                                    if ($scope.files && $scope.files.length) {
                                        $scope.upload();
                                    } else {
                                        $scope.loadInProgress = false;
                                        if (!$scope.notLoadedFiles.length) {
                                            ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                        } else {
                                           ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                        }
                                        $uibModalInstance.close();
                                    }
                                }
                            }
                        }).catch( function( response ) {
                            //console.log('4 File upload error:', file.name);
                            $scope.notLoadedFiles.push(file.name);
                            if ($scope.files && $scope.files.length) {
                                $scope.upload();
                            } else {
                                //console.log('Больше нет файлов');
                                ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                $uibModalInstance.close();
                            }
                        });
                }
                else if ($scope.contractId) {
                    documentsService.save(file, $scope.tenderId, {awardId: $scope.awardId, contractId: $scope.contractId})
                        .then(function(response) {
                            if (response) {
                                documentsService.edit($scope.tenderId, response.data.data.id, {awardId: $scope.awardId, contractId: $scope.contractId}, {documentType: $scope.document.documentType})
                                    .then(function() {
                                        if ($scope.files && $scope.files.length) {
                                            $scope.upload();
                                        } else {
                                            $scope.loadInProgress = false;
                                            if (!$scope.notLoadedFiles.length) {
                                                ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                            } else {
                                               ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                            }
                                            $uibModalInstance.close();
                                        }
                                    });
                            }
                        }).catch( function( response ) {
                            //console.log('5 File upload error:', file.name);
                            $scope.notLoadedFiles.push(file.name);
                            if ($scope.files && $scope.files.length) {
                                $scope.upload();
                            } else {
                                //console.log('Больше нет файлов');
                                ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                $uibModalInstance.close();
                            }
                        });
                }
            }
            else if ($scope.document.documentOf == "lot") {
                console.log('$scope.document.documentOf == "lot"')
                documentsService.save(file, $scope.tenderId, {lotId: $scope.document.relatedItem})
                    .then(function(response) {
                        if (response) {
                            documentsService.edit($scope.tenderId, response.data.data.id, {lotId: $scope.document.relatedItem}, {documentType: $scope.document.documentType})
                                .then(function() {
                                    if ($scope.files && $scope.files.length) {
                                        $scope.upload();
                                    } else {
                                        $scope.loadInProgress = false;
                                        if (!$scope.notLoadedFiles.length) {
                                            ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                        } else {
                                           ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                        }
                                        $uibModalInstance.close();
                                    }
                                });
                        }
                    }).catch( function( response ) {
                        //console.log('6 File upload error:', file.name);
                        $scope.notLoadedFiles.push(file.name);
                        if ($scope.files && $scope.files.length) {
                            $scope.upload();
                        } else {
                            //console.log('Больше нет файлов');
                            ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                            $uibModalInstance.close();
                        }
                    });
            }
            else if ($scope.document.documentOf == "item") {
                console.log('$scope.document.documentOf == "item"')
                documentsService.save(file, $scope.tenderId, {itemId: $scope.document.relatedItem})
                    .then(function(response) {
                        if (response) {
                            documentsService.edit($scope.tenderId, response.data.data.id, {itemId: $scope.document.relatedItem}, {documentType: $scope.document.documentType})
                                .then(function() {
                                    if ($scope.files && $scope.files.length) {
                                        $scope.upload();
                                    } else {
                                        $scope.loadInProgress = false;
                                        if (!$scope.notLoadedFiles.length) {
                                            ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                                        } else {
                                           ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                                        }
                                        $uibModalInstance.close();
                                    }
                                });
                        }
                    }).catch( function( response ) {
                        //console.log('7 File upload error:', file.name);
                        $scope.notLoadedFiles.push(file.name);
                        if ($scope.files && $scope.files.length) {
                            $scope.upload();
                        } else {
                            //console.log('Больше нет файлов');
                            ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                            $uibModalInstance.close();
                        }
                    });
            }
            if (request) {
                console.log('request')
                request.then(function() {
                    $scope.loadInProgress = false;
                    if (!$scope.notLoadedFiles.length) {
                        ntFlash.success( gettext( 'Документы успешно загружены.' ) );
                    } else {
                       ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+$scope.notLoadedFiles.join(', '));
                    }
                    $uibModalInstance.close();
                });
            }
        }
    }

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.deleteDoc = function(index) {
        $scope.files.splice(index, 1);
    };

    $scope.$watch('file', function(val) {
        if ($scope.file && $scope.file.length) {
            $scope.files = [...$scope.files, ...val];
        }
    });

});
