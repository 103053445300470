commons.factory('loadExternalDataService', function(http, $window) {


    const current_tag = 'https://raw.githubusercontent.com/ProzorroUKR/standards/refs/heads/master/'

    const proxyUrl = 'proxy_doc_download/?doc_url=';
    const getCriteriasResource = current_tag +'criteria/article_17.json';
    const getOtherCriteriasResource = current_tag +'criteria/other.json';
    const getGuaranteeCriteriasResource = current_tag +'criteria/tender_guarantee.json';
    const getContractGuaranteeCriteriasResource = current_tag +'criteria/contract_guarantee.json';
    const getLccCriteriasResource = current_tag +'criteria/LCC.json';
    const classifierObjections16 = current_tag +'criteria/article_16.json';
    const violation_amcu = current_tag +'AMCU/violation_amcu.json';
    const amcu = current_tag +'AMCU/amcu.json';
    const amcu_24 = current_tag +'AMCU/amcu_24.json';
    const requested_remedies_type = current_tag +'AMCU/requested_remedies_type.json';
    const local_origin_level = current_tag +'criteria/local_origin_level.json';
    const reporting_cause_master = current_tag +'codelists/tender/tender_cause.json';


    function createLoadFunc(url, storageName) {
        return function() {
            const cache = $window.localStorage.getItem(storageName);
            const timeStamp = Number(localStorage.getItem(storageName + 'TimeStamp'));
            return new Promise((resolve, reject) => {
                if (cache && (timeStamp + 60 * 60 * 1000) > Date.now()) {
                    // console.log('loadExternalDataService', storageName, 'cache', cache);
                    resolve(JSON.parse(cache));
                } else {
                    http.get(proxyUrl + encodeURIComponent(url) + '&view=proxy', false)
                        .then(result => {
                            // console.log('loadExternalDataService', storageName, 'result', result);
                            $window.localStorage.setItem(storageName, JSON.stringify(result.data));
                            $window.localStorage.setItem(storageName + 'TimeStamp', Date.now().toString());
                            resolve(result.data);
                        })
                        .catch(error => reject(error));
                }
            });
        }
    }

    return {
        getCriterias: createLoadFunc(getCriteriasResource, 'criterias'),
        getOtherCriterias: createLoadFunc(getOtherCriteriasResource, 'otherCriterias'),
        getGuaranteeCriterias: createLoadFunc(getGuaranteeCriteriasResource, 'guaranteeCriterias'),
        getContractGuaranteeCriterias: createLoadFunc(getContractGuaranteeCriteriasResource, 'contractGuaranteeCriterias'),
        getLccCriterias: createLoadFunc(getLccCriteriasResource, 'lccCriterias'),
        classifierObjections16: createLoadFunc(classifierObjections16, 'classifierObjections16'),
        violation_amcu: createLoadFunc(violation_amcu, 'violation_amcu'),
        amcu: createLoadFunc(amcu, 'amcu'),
        requested_remedies_type: createLoadFunc(requested_remedies_type, 'requested_remedies_type'),
        local_origin_level: createLoadFunc(local_origin_level, 'local_origin_level'),
        amcu_24: createLoadFunc(amcu_24, 'amcu_24'),
        reporting_cause: createLoadFunc(reporting_cause_master, 'reporting_cause_master'),
    }
});
