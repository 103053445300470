providerCabinetApp.config([
    '$stateProvider',
    '$urlRouterProvider',
    'CONTACT_TYPES',
    function (
        $stateProvider,
        $urlRouterProvider,
        CONTACT_TYPES
    ) {

        $urlRouterProvider.otherwise('/home/');
        $urlRouterProvider.when('/profile', '/login');
        $urlRouterProvider.when('/tender/:id', '/tender/:id/overview');

        $stateProvider
            .state('goHome', {
                onEnter: function ($location) {
                    $location.path("/");
                }
            })
            .state('notificationsList', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: `/static/scripts/modules/Provider/views/sidebars/sidebar-notifications.html`,
                        controller: 'sidebarCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Notifications/views/notifications.html"
                    }
                },
                url: '/notifications/'
            })
            .state('notificationsList.all', {
                url: '^/notifications/',
                views: {
                    '': {
                        templateUrl: '/static/scripts/modules/Notifications/views/notifications.all.html',
                        controller: 'notificationsAllCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('tendersList', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: function ($stateParams) {
                            return '/static/scripts/modules/Provider/views/sidebars/sidebar.html';
                        },
                        controller: 'sidebarCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/TendersList/views/tenders.html",
                        controller: 'tendersCtrl'
                    }
                },
                params: {
                    list: 'home'
                },
                url: '/:list/'
            })

            .state('tendersList.type', {
                url: '^/:list/?pageNum&query&status&tendersOnlyOf&procurementMethodType&cpv&dkpp&inn&region&amount_gte&amount_lte&createReport&scpv&funders&fund_name&i&bookmark&complaints_checked&cs&create_gte&create_lte&tp_gte&tp_lte&ap_gte&ap_lte&covid&edrpou_buyer&edrpou_supplier&test',
                views: {
                    '': {
                        templateUrl: '/static/scripts/modules/TendersList/views/tenders-list.html',
                        controller: 'tendersListCtrl'
                    }
                },
                params: {
                    status: '',
                    tendersOnlyOf: '',
                    pageNum: '1',
                    query: '',
                    cpv: null,
                    dkpp: null,
                    inn: null,
                    procurementMethodType: '',
                    region: '',
                    amount_gte: '',
                    amount_lte: '',
                    scpv: '',
                    i: '',
                    bookmark: '',
                    create_gte: '',
                    create_lte: '',
                    tp_gte: '',
                    tp_lte: '',
                    ap_gte: '',
                    ap_lte: '',
                    cs: '',
                    city: ''
                }
            })
            .state('tendersList.type.cpv', {
                views: {
                    'classifier@tendersList': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('tendersList.type.dkpp', {
                views: {
                    'classifier@tendersList': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('tendersList.type.inn', {
                views: {
                    'classifier@tendersList': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('editTender', {
                url: '/create-tender/:lots/:tenderProcedure/:entity/:id',
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-default.html"
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/TenderEdit/views/tender-edit.html",
                        controller: 'tenderEditCtrl'
                    }
                },
                params: {
                    lots: "singlelot",
                    tenderProcedure: "belowThreshold"
                },
                resolve: {
                    planData: function ($stateParams, planningService) {
                        if ($stateParams.entity == 'plan') {
                            return planningService.get($stateParams.id);
                        } else {
                            return null;
                        }
                    },
                    tenderData: ($stateParams, tenderService, $q) => {
                        if ($stateParams.entity == 'tender') {
                            return $stateParams.id ? tenderService.get($stateParams.id) : $q.resolve(null);
                        } else {
                            return null;
                        }
                    },
                    isCreateMode: () => true,
                }
            })
            .state('edit.quality', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Quality/views/quality.html",
                        controller: 'qualityCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('editTender.quality', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Quality/views/quality.html",
                        controller: 'qualityCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('editTender.lot', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Lot/views/lot.html",
                        controller: 'lotCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    lotId: null
                }
            })
            .state('editTender.criteria', {
                views: {
                    'criteria': {
                        templateUrl: "/static/scripts/modules/Criteria/views/criteria.html",
                        controller: 'criteriaCtrl'
                    }
                },
            })
            .state('editTender.lot.classifier', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('editTender.classifier', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('createTenderAgreement', {
                url: '/create-tender-agreements/:lots/:tenderProcedure/:agreementId',
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-default.html"
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/TenderEdit/views/tender-edit.html",
                        controller: 'tenderEditCtrl'
                    }
                },
                params: {
                    lots: null,
                    tenderProcedure: null,
                    agreementId: null
                },
                resolve: {
                    planData: () => null,
                    tenderData: ($stateParams, tenderService, $q) => {
                        // return $stateParams.tender ? tenderService.get($stateParams.tender) : $q.resolve(null);
                        // return $q.resolve(null);
                    },
                    isCreateMode: () => true,
                }
            })
            .state('createTenderAgreement.criteria', {
                views: {
                    'criteria': {
                        templateUrl: "/static/scripts/modules/Criteria/views/criteria.html",
                        controller: 'criteriaCtrl'
                    }
                },
            })
            .state('createTenderAgreement.lot', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Lot/views/lot.html",
                        controller: 'lotCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    lotId: null
                }
            })
            .state('createTenderAgreement.lot.classifier', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('createTenderAgreement.classifier', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('tenderView', {
                url: '/tender/:id?_url',
                views: {
                    'sidebar': {
                        controller: 'sidebarTenderCtrl',
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-tender.html"
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.html",
                        controller: 'tenderViewCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{tender.tenderID}}'
                },
                resolve: {
                    tenderData: function ($stateParams, tenderService) {
                        return tenderService.get($stateParams.id, $stateParams._url);
                    }
                },
            })
            .state('tenderView.edit', {
                url: '/edit',
                templateUrl: "/static/scripts/modules/TenderEdit/views/tender-edit.html",
                controller: 'tenderEditCtrl',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                resolve: {
                    planData: () => null,
                    isCreateMode: () => false,
                }

            })
            .state('tenderView.edit.quality', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Quality/views/quality.html",
                        controller: 'qualityCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('tenderView.edit.lot', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Lot/views/lot.html",
                        controller: 'lotCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    lotId: null
                }
            })
            .state('tenderView.edit.lot.classifier', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('tenderView.edit.classifier', {
                views: {
                    'lot': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                    type: null,
                }

            })
            .state('tenderView.overview', {
                url: '/overview',
                views: {
                    '': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.overview.html",
                        controller: 'tenderViewOverviewCtrl'
                    },
                    'documents': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                        controller: 'tenderViewOverviewDocumentsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Описание")
                }
            })
            .state('tenderView.documents', {
                url: '/documents',
                views: {
                    '': {
                        controller: 'tenderViewDocumentsCtrl',
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.html"
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Документы")
                }
            })
            .state('tenderView.ownDocuments', {
                url: '/own-documents',
                views: {
                    '': {
                        controller: 'tenderViewDocumentsCtrl',
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.html"
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Документы")
                }
            })
            .state('tenderView.ownBid', {
                url: '/own-bid',
                views: {
                    '': {
                        controller: 'tenderViewOwnBidCtrl',
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.own-bid.html"
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Ваше предложение")
                }
            })
            .state('tenderView.chat', {
                url: '/chat',
                views: {
                    '': {
                        controller: 'tenderViewChatCtrl',
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.chat.html"
                    },
                    'documents': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                        controller: 'tenderViewOverviewDocumentsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Уточнения") // Never display this state in breadcrumb.
                }
            })
            .state('tenderView.complaint', {
                url: '/complaint',
                views: {
                    '': {
                        controller: 'tenderViewComplaintCtrl',
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.complaint.html"
                    },
                    'documents': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                        controller: 'tenderViewOverviewDocumentsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: gettext("Обжалование") // Never display this state in breadcrumb.
                }
            })
            .state('tenderView.prequalification', {
                url: '/prequalification',
                views: {
                    '': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.prequalification.html",
                        controller: 'tenderViewPrequalificationCtrl'
                    },
                    'documents': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                        controller: 'tenderViewOverviewDocumentsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: gettext('Преквалификация')
                }
            })
            .state('tenderView.agreements', {
                url: '/agreements',
                controller: function (tenderData, $state) {
                    if (tenderData.data.agreements && tenderData.data.agreements.length > 0) {
                        $state.go('tenderView.agreements.one', {
                            agreementId: tenderData.data.agreements[0].id
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },

            })
            .state('tenderView.agreements.one', {
                url: '/:agreementId',
                views: {
                    '@tenderView': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.agreements.html",
                        controller: 'tenderViewAgreementsCtrl'
                    },
                    // 'documents': {
                    //     templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                    //     controller: 'tenderViewOverviewDocumentsCtrl'
                    // }
                },
                ncyBreadcrumb: {
                    label: gettext('Соглашения')
                }
            })
            .state('tenderView.agreements.one.contracts', {
                url: '/contracts/:contractId',
                views: {
                    '@tenderView': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.agreements.contract.html",
                        controller: 'tenderViewAgreementsContractCtrl',
                    }
                },
                ncyBreadcrumb: {
                    label: gettext('Участник соглашения')
                }
            })
            .state('tenderView.auction', {
                url: '/auction?lotId',
                views: {
                    '': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.auction.html",
                        controller: 'tenderViewAuctionCtrl'
                    },
                    'documents': {
                        templateUrl: "/static/scripts/modules/TenderView/views/tender-view.documents.short.html",
                        controller: 'tenderViewOverviewDocumentsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: gettext('Торги')
                }
            })
            .state('tenderView.contracts', {
                url: '/contracts',
                templateUrl: "/static/scripts/modules/TenderView/views/tender-view.contracts.html",
                controller: 'tenderViewContractCtrl',
                ncyBreadcrumb: {
                    label: gettext('Договоры')
                },
                resolve: {
                    tenderData: function ($stateParams, tenderService) {
                        return tenderService.get($stateParams.id, $stateParams._url);
                    }
                },
            })
            .state('tenderView.bid', {
                url: '/:lotId/:bidId',
                templateUrl: "/static/scripts/modules/TenderView/views/tender-view.auction.bid.html",
                controller: 'tenderViewAuctionBidCtrl',
                ncyBreadcrumb: {
                    label: gettext('Предложение')
                }
            })
            .state('tenderView.monitoring', {
                // abstract: true,
                url: '/monitorings',
                templateUrl: "/static/scripts/modules/Monitoring/views/monitoring.list.html",
                controller: 'monitoringListCtrl',
                ncyBreadcrumb: {
                    label: gettext("Мониторинг")
                },
            })
            .state('tenderView.monitoring_overview', {
                url: '/monitorings/:monitoring_id/overview',
                controller: 'monitoringOverviewCtrl',
                templateUrl: "/static/scripts/modules/Monitoring/views/monitoring.overview.html",
                ncyBreadcrumb: {
                    label: gettext("Мониторинг") + ' {{monitoring.monitoring_id}}'
                },
            })
            .state('planning', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-planning.html",
                        controller: 'sidebarCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Planning/views/planning.html"
                    }
                },
                url: '/plans'
            })
            .state('planning.overview', {
                url: '/:id/overview',
                templateUrl: "/static/scripts/modules/Planning/views/planning-overview.html",
                controller: 'planningOverviewCtrl',
                resolve: {
                    planData: function ($stateParams, planningService) {
                        return planningService.get($stateParams.id);
                    }
                }
            })
            .state('planning.edit', {
                url: '/:id/edit',
                templateUrl: "/static/scripts/modules/Planning/views/planning-create.html",
                controller: 'planningEditCtrl',
                resolve: {
                    planData: function ($stateParams, planningService) {
                        return planningService.get($stateParams.id);
                    }
                }
            })
            .state('planning.edit.kekv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true,
                    checked: [],

                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('planning.edit.dkpp', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                }

            })
            .state('planning.edit.kpkv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                }

            })
            .state('planning.edit.cpv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                }

            })
            .state('planning.edit.inn', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    //multi: true,
                    checked: [],
                    codes: [],
                }
            })
            .state('planning.edit.atc', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    //multi: true,
                    checked: [],
                    codes: [],
                }
            })
            .state('planning.create', {
                url: '/create',
                templateUrl: "/static/scripts/modules/Planning/views/planning-create.html",
                controller: 'planningCreateCtrl'
            })
            .state('planning.create.kekv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true,
                    checked: [],
                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('planning.create.kpkv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true,
                    checked: [],
                },
                data: {
                    disableScrollPageToTop: true
                }
            })
            .state('planning.create.dkpp', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                }

            })
            .state('planning.create.inn', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    //multi: true,
                    checked: [],
                    codes: [],
                }
            })
            .state('planning.create.atc', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    //multi: true,
                    checked: [],
                    codes: [],
                }
            })
            .state('planning.create.cpv', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    checked: [],
                }
            })
            .state('planning.list', {
                abstract: true,
                url: '/:type',
                params: {
                    type: 'all'
                },
                templateUrl: "/static/scripts/modules/Planning/views/planning-plans.html",
                controller: 'planningPlansCtrl'
            })
            .state('planning.list.type', {
                url: '/?pageNum&query&status&procurementMethodType&cpv&dkpp&amount_gte&amount_lte&createReport&create_gte&create_lte&tp_gte&tp_lte',
                params: {
                    pageNum: '1',
                    query: '',
                    status: '',
                    procurementMethodType: '',
                    cpv: null,
                    dkpp: null,
                    amount_gte: '',
                    amount_lte: '',
                    createReport: '',
                    create_gte: '',
                    create_lte: '',
                    tp_gte: '',
                    tp_lte: ''
                },
                templateUrl: "/static/scripts/modules/Planning/views/planning-plans-list.html",
                controller: 'planningPlansListCtrl'
            })
            .state('planning.list.type.cpv', {
                views: {
                    'classifier@planning.list': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('planning.list.type.dkpp', {
                views: {
                    'classifier@planning.list': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            // .state('planning.list', {
            //     url: '/list',
            //     templateUrl: "/static/scripts/modules/Planning/views/planning-plans.html",
            //     controller: 'planningPlansCtrl'
            // })
            // .state('planning.list.all', {
            //     url: '/all/page/:pageNum',
            //     templateUrl: "/static/scripts/modules/Planning/views/planning-plans-list.html",
            //     controller: 'planningPlansListCtrl',
            //     params: {
            //         q: null,
            //         list: "all",
            //         pageNum: '1'
            //     }
            // })
            // .state('planning.list.search', {
            //     url: '/search/page/:pageNum?q',
            //     templateUrl: "/static/scripts/modules/Planning/views/planning-plans-list.html",
            //     controller: 'planningPlansListCtrl',
            //     params: {
            //         list: "search",
            //         pageNum: '1',
            //         q: null
            //     }
            // })
            // .state('planning.list.my', {
            //     url: '/my-plans/page/:pageNum',
            //     templateUrl: "/static/scripts/modules/Planning/views/planning-plans-list.html",
            //     controller: 'planningPlansListCtrl',
            //     params: {
            //         list: "my-plans",
            //         pageNum: '1'
            //     }
            // })
            // .state('planning.list.company', {
            //     url: '/company-plans/page/:pageNum',
            //     templateUrl: "/static/scripts/modules/Planning/views/planning-plans-list.html",
            //     controller: 'planningPlansListCtrl',
            //     params: {
            //         list: "company-plans",
            //         pageNum: '1'
            //     }
            // })
            .state('contract', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-contract.html"
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Contract/views/contract.html"
                    }
                },
                url: '/contract/:id',
                resolve: {
                    contractData: function ($stateParams, contractingService) {
                        return contractingService.get($stateParams.id);
                    }
                }
            })
            .state('contract.edit', {
                url: '/edit',
                templateUrl: "/static/scripts/modules/Contract/views/contract-edit.html",
                controller: 'contractEditCtrl'
            })
            .state('contract.overview', {
                url: '/overview',
                templateUrl: "/static/scripts/modules/Contract/views/contract-overview.html",
                controller: 'contractOverviewCtrl'
            })
            .state('contract.documents', {
                url: '/documents',
                templateUrl: "/static/scripts/modules/Contract/views/contract-documents.html",
                controller: 'contractDocumentsCtrl'
            })
            .state('contract.changes', {
                url: '/changes',
                templateUrl: "/static/scripts/modules/Contract/views/contract-changes.html",
                controller: 'contractChangesCtrl'
            })
            .state('contracts', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-contracts.html",
                        controller: 'sidebarCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Contract/views/contract.html"
                    }
                },
                url: '/contracts'
            })
            .state('contracts.list', {
                url: '/list',
                templateUrl: "/static/scripts/modules/Contract/views/contract-list.html",
                controller: 'contractListCtrl'
            })
            .state('contracts.list.all', {
                url: '/all/page/:pageNum',
                templateUrl: "/static/scripts/modules/Contract/views/contract-list-all.html",
                controller: 'contractListAllCtrl',
                params: {
                    q: null,
                    list: "all",
                    pageNum: '1'
                }
            })
            .state('contracts.list.search', {
                url: '/search/page/:pageNum?q',
                templateUrl: "/static/scripts/modules/Contract/views/contract-list-all.html",
                controller: 'contractListAllCtrl',
                params: {
                    q: null,
                    list: "search",
                    pageNum: '1'
                }
            })
            .state('contracts.list.my', {
                url: '/my-contracts',
                templateUrl: "/static/scripts/modules/Contract/views/contract-list-all.html",
                controller: 'contractListAllCtrl',
                params: {
                    list: "user-contacts",
                    pageNum: '1'
                }
            })
            .state('contracts.list.company', {
                url: '/company-contacts',
                templateUrl: "/static/scripts/modules/Contract/views/contract-list-all.html",
                controller: 'contractListAllCtrl',
                params: {
                    list: "company-contacts",
                    pageNum: '1'
                }
            })
            .state('login', {
                url: '/login',
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-profile.html",
                        controller: 'sidebarProfileCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Profile/views/profile.login.html",
                        controller: ($rootScope, $scope, $state) => {
                            //$scope.isAnonymous = $rootScope.isAnonymous;
                            if (!$rootScope.isAnonymous) {
                                $state.go('profile.user');
                            }
                        }
                    }
                }
            })
            .state('profile', {
                abstract: true,
                url: '/profile',
                data: {
                    permissions: {
                        only: ['AUTHORIZED', 'GUEST'],
                        redirectTo: 'login'
                    }
                },
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar-profile.html",
                        controller: 'sidebarProfileCtrl'
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/Profile/views/profile.html"
                    }
                }
            })
            .state('profile.user', {
                url: '/user',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                template: "<profile-user></profile-user>"
            })
            .state('profile.sign-docs', {
                url: '/sign-docs',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.signDoc.html",
                controller: 'signDocumentsCtrl',
                controllerAs: 'vm',
            })
            .state('profile.notifications', {
                url: '/notifications',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                template: "<profile-user-notifications></profile-user-notifications>"
            })
            .state('profile.activity', {
                // url: '/activity/?pageNum&pageSize',
                url: '/activity/',
                // params: {
                //     pageNum: '1',
                //     pageSize: '10'
                // },
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }

                },
                template: "<profile-user-activity-log></profile-user-activity-log>"
            })
            // .state('profile.activity.page', {
            //     url: '/activity/?pageNum&pageSize',
            //     params: {
            //         pageNum: '1',
            //         pageSize: '10'
            //     },
            //     data: {
            //         permissions: {
            //             only: ['AUTHORIZED'],
            //             redirectTo: 'login'
            //         }
            //
            //     },
            //     template: "<profile-user-activity-log></profile-user-activity-log>"
            // })
            .state('profile.administrator-notifications', {
                url: '/administrator-notifications',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                template: "<profile-user-administrator-notifications></profile-user-administrator-notifications>"
            })
            .state('profile.calendar', {
                url: '/calendar',
                templateUrl: "/static/scripts/modules/Calendar/views/calendar.html",
                controller: 'calendarCtrl',
                controllerAs: 'vm',
            })
            .state('profile.contacts', {
                url: '/contacts?h',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                template: '<profile-contacts contact-type="contactType" confirmation-code="confirmationCode" ></profile-contacts>',
                params: {
                    h: null,
                    ct: null
                },
                resolve: {

                     contactType: ($stateParams) => $stateParams.ct,
                     confirmationCode: ($stateParams) => $stateParams.h
                }
            })
            .state('profile.phones', {
                url: '/phones',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                template: '<profile-contacts user="user" ct="ct"></profile-contacts>',
                params: {
                    ct: 10 // harcoded CONTACT_TYPES.PHONE
                },

            })
            .state('profile.company', {
                url: '/company',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.html",
                controller: 'companyCtrl',
                controllerAs: 'vm',
                resolve: {
                    userCompany: profileService => {
                        return profileService.getCompany()
                            .then(res => res.data);
                    }
                }
            })
            .state('profile.password', {
                url: '/password',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.password.html",
                controller: 'userChangePassword',
                controllerAs: 'vm'
            })
            .state('profile.reset-password', {
                url: '/password/reset/:hash/',
                data: {
                    permissions: {
                        only: ['GUEST'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.reset-password.html",
                controller: 'userResetPassword',
                controllerAs: 'vm'
            })
            /*.state('profile.confirm-email', {
                url: '/confirm/email/:hash/',
                templateUrl: "/static/scripts/modules/Profile/views/profile.reset-password.html",
                controller: 'userConfirmContact',
                controllerAs: 'vm',
                data: {
                    permissions: {
                        only: ['GUEST'],
                        redirectTo: 'home'
                    }
                }
            })*/
            .state('profile.transactions', {
                url: '/wallets',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.transactions.html",
                controller: 'billingCtrl'
            })
            .state('profile.invoices', {
                url: '/invoices',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.invoices.html",
                controller: 'billingCtrl'
            })
            .state('profile.reports', {
                url: '/reports',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.reports.html",
                controller: 'reportsCtrl'
            })
/* *************************************************************************************************** */
            .state('profile.companies', {
                abstract: true,
                url: '/companies'
            })
            .state('profile.companies.list', {
                url: '/:type/?pageNum&query&region&cpv&createReport',
                params: {
                    isCompanies: true,
                    type: 'buyer',
                    pageNum: '1',
                    query: '',
                    region: '',
                    cpv: null,
                    createReport: ''
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.list.html",
                controller: 'companiesListCtrl'
            })
            .state('profile.companies.list.cpv', {
                views: {
                    'classifier@profile.companies.list': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('profile.companies.overview', {
                url: '/:type/:edrpou/overview',
                params: {
                    isCompany: true
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.overview.html",
                controller: 'companiesOverviewCtrl'
            })
            .state('profile.companies.tenders', {
                abstract: true,
                url: '/:type/:edrpou/tenders',
                params: {
                    isCompany: true,
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.tenders.html",
                controller: 'companiesTendersCtrl'
            })
            .state('profile.companies.tenders.type', {
                url: '/?pageNum&query&status&procurementMethodType&cpv&dkpp&inn&region&amount_gte&amount_lte&createReport&funders&fund_name&bookmark&complaints_checked&cs&create_gte&create_lte&tp_gte&tp_lte&ap_gte&ap_lte&covid',
                params: {
                    pageNum: '1',
                    query: '',
                    status: '',
                    procurementMethodType: '',
                    cpv: null,
                    dkpp: null,
                    inn: null,
                    region: '',
                    amount_gte: '',
                    amount_lte: '',
                    createReport: '',
                    funders: '',
                    fund_name: '',
                    bookmark: '',
                    complaints_checked: '',
                    cs: '',
                    create_gte: '',
                    create_lte: '',
                    tp_gte: '',
                    tp_lte: '',
                    ap_gte: '',
                    ap_lte: ''
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.tenders.list.html",
                controller: 'companiesTendersListCtrl'
            })
            .state('profile.companies.tenders.type.cpv', {
                views: {
                    'classifier@profile.companies.tenders': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('profile.companies.tenders.type.dkpp', {
                views: {
                    'classifier@profile.companies.tenders': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('profile.companies.tenders.type.inn', {
                views: {
                    'classifier@profile.companies.tenders': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('profile.companies.plans', {
                abstract: true,
                url: '/:type/:edrpou/plans',
                params: {
                    isCompany: true
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.plans.html",
                controller: 'companiesPlansCtrl'
            })
            .state('profile.companies.plans.type', {
                url: '/?pageNum&query&status&procurementMethodType&cpv&dkpp&amount_gte&amount_lte&createReport&create_gte&create_lte&tp_gte&tp_lte',
                params: {
                    pageNum: '1',
                    query: '',
                    status: '',
                    procurementMethodType: '',
                    cpv: null,
                    dkpp: null,
                    amount_gte: '',
                    amount_lte: '',
                    createReport: '',
                    create_gte: '',
                    create_lte: '',
                    tp_gte: '',
                    tp_lte: ''
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.companies.plans.list.html",
                controller: 'companiesPlansListCtrl'
            })
            .state('profile.companies.plans.type.cpv', {
                views: {
                    'classifier@profile.companies.plans': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
            .state('profile.companies.plans.type.dkpp', {
                views: {
                    'classifier@profile.companies.plans': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                params: {
                    multi: true
                }
            })
/* *************************************************************************************************** */
            .state('profile.receipts', {
                url: '/receipts',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.receipts.html",
                controller: 'receiptsCtrl'
            })
            /*.state('profile.registration', {
                url: '/registration',
                templateUrl: "/static/scripts/modules/Profile/views/profile.registration.html",
                controller: 'userRegistrationController',
                controllerAs: 'vm'
            })*/
            .state('profile.registration', {
                url: '/registration?utm_source&utm_campaign&utm_medium',
                data: {
                    permissions: {
                        only: ['GUEST'],
                        redirectTo: 'home'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.registration.html",
                controller: 'userRegistrationWithCompanyController',
                controllerAs: 'vm',
                params: {
                    utm_source: null,
                    utm_medium: null,
                    utm_campaign: null
                },
                resolve: {
                    utmSource: ($stateParams) => {
                        return {
                            utm_source: $stateParams.utm_source,
                            utm_medium: $stateParams.utm_medium,
                            utm_campaign: $stateParams.utm_campaign
                        };
                    }
                }
            })
            .state('profile.registered', {
                url: '/registered',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.registered.html"
            })
            .state('profile.recovery', {
                url: '/recovery',
                data: {
                    permissions: {
                        only: ['GUEST'],
                        redirectTo: 'home'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.recovery-password.html",
                controller: 'userRecoveryPasswordController',
                controllerAs: 'vm'
            })
            .state('profile.companyCode', {
                url: '/company-code',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.code.html",
                controller: 'userCompanyCodeController',
                controllerAs: 'vm'
            })
            .state('profile.company-create', {
                url: '/company-create',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.create.html",
                controller: 'userCompanyController',
                controllerAs: 'vm',
                resolve: {
                    userCompanies: (profileService) => {
                        return profileService.getUser()
                            .then(res => res.data.companies);
                    },
                    requestsToTheCompany: (profileService) => {
                        return profileService.getUser()
                            .then(res => profileService.getUserRequests(res.data))
                            .then(res => res.data);
                    }
                }
            })
            .state('profile.company-created', {
                url: '/company-created',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.created.html"
            })
            .state('profile.company-request', {
                url: '/company-request',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.request.html",
                controller: 'userCompanyController',
                controllerAs: 'vm',
                resolve: {
                    userCompanies: (profileService) => {
                        return profileService.getUser()
                            .then(res => res.data.companies);
                    },
                    requestsToTheCompany: (profileService) => {
                        return profileService.getUser()
                            .then(res => profileService.getUserRequests(res.data))
                            .then(res => res.data);
                    }
                }
            })
            .state('profile.companyUsers', {
                url: '/company-users',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.company.users.html",
                controller: 'userCompanyUsersController',
                controllerAs: 'vm'
            })
            .state('profile.subscription', {
                url: '/subscription',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.subscription.html",
                controller: 'userSubscriptionController',
                controllerAs: 'vm'
            })
            .state('profile.subscription-new', {
                url: '/subscription-new/',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.subscription.new.html",
                controller: 'newSubscriptionCtrl',
                controllerAs: 'vm'
            })
            .state('profile.subscription-edit', {
                url: '/subscription-new/:id',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.subscription.edit.html",
                controller: 'newSubscriptionEditCtrl',
                controllerAs: 'vm',
                resolve: {
                    subscriptionData: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('profile.subscription-add', {
                url: '/subscription-new/add',
                data: {
                    permissions: {
                        only: ['AUTHORIZED'],
                        redirectTo: 'login'
                    }
                },
                templateUrl: "/static/scripts/modules/Profile/views/profile.subscription.edit.html",
                controller: 'newSubscriptionEditCtrl',
                controllerAs: 'vm',
                resolve: {
                    subscriptionData: function () {
                        return false;
                    }
                }
            })
            .state('profile.subscription-add.classifier', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    multi: true,
                    checked: [],
                    type: null,
                    codes: []
                }

            })
            .state('profile.subscription-edit.classifier', {
                views: {
                    'classifier': {
                        templateUrl: "/static/scripts/modules/Classifier/views/classifier.html",
                        controller: 'classifierCtrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    disableScrollPageToTop: true
                },
                params: {
                    multi: true,
                    checked: [],
                    type: null,
                    codes: []
                }
            })
            .state('archive', {
                abstract: true,
                url: '/:list/page/:pageNum',
                views: {
                    'sidebar': {
                        templateUrl: "/static/scripts/modules/Provider/views/sidebars/sidebar.html"
                    },
                    'main': {
                        templateUrl: "/static/scripts/modules/TendersList/views/tenders.html",
                        controller: 'tendersCtrl'
                    }
                },
                data: {
                    filterType: 'unsuccessful,complete,cancelled',
                    filterUser: 'user'
                }
            })
            .state('archive.page', {
                url: '^/:list/:type/page/:pageNum',
                templateUrl: "/static/scripts/modules/TendersList/views/tenders-list.html",
                controller: 'tendersListCtrl'
            })
            .state('agreementList', {
                url: '/agreement-list/:pageNum?:type',
                views: {
                    'main': {
                        component: 'agreementList',
                    },
                },
            })
            .state('agreementChangeList', {
                url: '/agreement-change/:id',
                views: {
                    'main': {
                        component: 'agreementChangeList',
                    },
                    'sidebar': {
                        component: "agreementSidebar"
                    },
                },
            })
            .state('agreementPage', {
                url: '/agreement/:id',
                views: {
                    'main': {
                        component: "agreementPage"
                    },
                    'sidebar': {
                        component: "agreementSidebar"
                    },
                    // resolve: {
                    //     tenderData: function ($stateParams, tenderService) {
                    //         return tenderService.get($stateParams.id, $stateParams._url);
                    //     }
                    // },
                },
            });
    }
]);
