commons.filter('procurementMethodType', () => {
    "use strict";
    return (input, module) => {
        let name = '';

        switch (input) {
            case 'priceQuotation': // prozorro market only
                name = gettext('Запит цінових пропозицій');
                break;

            case 'belowThreshold':
                name = gettext('Допороговые закупки');
                break;

            case 'aboveThresholdUA':
                name = gettext('Открытые торги');
                break;

            case 'aboveThreshold':
                name = gettext('Відкриті торги з особливостями');
                break;


            case 'competitiveOrdering':
                name = gettext('Тендер');
                break;

            case 'aboveThresholdEU':
                name = gettext('Открытые торги с публикацией на английском языке');
                break;

            case 'negotiation':
                name = gettext('Переговорная процедура');
                break;

            case 'negotiation.quick':
                name = gettext('Переговорная процедура (сокращенная)');
                break;

            case 'aboveThresholdUA.defense':
                name = gettext('Переговорная процедура для нужд обороны');
                break;

            case 'simple.defense':
                name = gettext('Упрощенные торги с применением электронной системы закупок');
                break;
    
            case 'competitiveDialogueUA':
                name = gettext('Конкурентный диалог 1-ый этап');
                break;

            case 'competitiveDialogueEU':
                name = gettext('Конкурентный диалог с публикацией на английском языке 1-ый этап');
                break;

            case 'competitiveDialogueUA.stage2':
                name = gettext('Конкурентный диалог 2-ой этап');
                break;

            case 'competitiveDialogueEU.stage2':
                name = gettext('Конкурентный диалог с публикацией на английском языке 2-ой этап');
                break;

            case 'reporting':
                name = gettext('Отчет о заключенном договоре');
                break;

            case 'esco':
                name = gettext('Открытые торги для закупки энергосервиса');
                break;

            case 'esco.negotiation':
                name = gettext('Открытые торги для закупки энергосервиса. Переговорная процедура');
                break;

            case 'closeFrameworkAgreementUA':
                name = gettext('Укладання рамкової угоди');
                break;

            case 'closeFrameworkAgreementSelectionUA':
                name = gettext('Відбір для закупівлі за рамковою угодою');
                break;

            case 'centralizedProcurement':
                name = gettext('Закупівля через ЦЗО');
                break;

            case 'requestForProposal':
                name = gettext('Торги за правилами організатора');
                break;

            case '':
                if (module === 'planning') {
                    name = gettext('Без применения электронной системы');
                }
                break;
        }

        return name ? name : input;
    };
});
